import axios from "axios";

window.prepare_new_mails = function() {
    var e = document.getElementById('prepare-new-mails-button');
    e.style.visibility = 'hidden';
    var e = document.getElementById('prepare-new-mails-button-2');
    e.classList.remove('hidden');
    var e = document.getElementById('cancel-new-mails-button');
    e.classList.remove('hidden');

    var els = document.getElementsByClassName('sent-mails-link');
    for (var i = 0, element; element = els[i++];) {
        element.classList.add('hidden');
    }

    var els = document.getElementsByClassName('check-address-mail');
    for (var i = 0, element; element = els[i++];) {
        element.classList.remove('hidden');
    }
};

window.find_address = function(id) {
    var input = document.getElementById('location-' + id);
    var e = document.getElementById("status-" + id);
    e.innerHTML = '...';
    $.get( "locate_address?address_id=" + id + "&address=" + input.value, function( data ) {
        e.innerHTML = data;
    });

};

window.prepare_new_mails_2 = function(project_id, distances) {
    console.log('start herex...')
    var ids = '';
    var checks = document.getElementsByClassName('send-mail-checkbox');
    for (var i = 0; checks[i]; i++) {
        var c = checks[i];
        var ch = c.checked;
        var id = c.id;
        var adr_id = id.split('_')[1];
        if (ch == true) {
            ids = ids + ((ids != '') ? '-' : '' ) + adr_id;
        };
    };

    window.location = `/mails/new_mail_form?project_id=` + project_id + '&address_ids=' + ids + '&mode=init' + '&distances=' + JSON.stringify(distances);
};

window.cancel_new_mails = function() {
    var e = document.getElementById('prepare-new-mails-button');
    e.style.visibility = 'visible';
    var e = document.getElementById('prepare-new-mails-button-2');
    e.classList.add('hidden');
    var e = document.getElementById('cancel-new-mails-button');
    e.classList.add('hidden');

    var els = document.getElementsByClassName('sent-mails-link');
    for (var i = 0, element; element = els[i++];) {
        element.classList.remove('hidden');
    }

    var els = document.getElementsByClassName('check-address-mail');
    for (var i = 0, element; element = els[i++];) {
        element.classList.add('hidden');
    }
};

window.close_popover = function(html_id) {
    var p = document.getElementById(html_id);
    p.style.visibility = 'hidden';
    p.innerHTML = '';
};

window.close_es_popover = function() {
    var e = document.getElementById('es-menu-popover-content-wrapper');
    e.style.display = 'none';

};
window.close_res_popover = function() {
    var e = document.getElementById('res-menu-popover-content-wrapper');
    e.style.display = 'none';
};

window.close_flashes = function() {
    $('.callout').hide();
};
