
console.log('Vite ⚡️ Rails started ...')

import * as Turbo from '@hotwired/turbo'
Turbo.start()
import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

// foundation and jQuery

import 'foundation-sites'

import $ from 'jquery';
window.$ = $;

// SVELTE

import '../javascript/components/initialize-svelte'

// STIMULUS

import { initStimulus } from "vite-stimulus-initializer";
const controllers = import.meta.glob('../javascript/**/*-controller.js', { eager: true })
initStimulus(controllers, 2, { debug: true, exclude: ['components', 'views'], folderSeparator: '-'  })

import '@csedl/stimulus_dropdown'

// custom javascript


import '../javascript/views/admin'
import '../javascript/views/login'
import '../javascript/views/project'
import '../javascript/views/overview_map'
import '../javascript/views/new-mail-form'

import '../javascript/layout/mails'
import '../javascript/layout/session_layout'
import '../javascript/layout/user_layout'

import '../javascript/components/initialize-svelte'
import '../javascript/components/locating_popover'
import '../javascript/components/location-pane.js'
import '../javascript/components/person-form'
import '../javascript/components/popovers'

import { initialize_popovers } from "@/javascript/components/popovers";

// initialize the page
window.addEventListener('load', (event) => {
    initPage();
});

window.addEventListener('turbo:render', (event) => {
    initPage();
});

function initPage() {
    $(document).foundation();
    initOverviewMap()
    initialize_popovers()

    console.log('foundation ready')
}

console.log('Vite ⚡️ Rails ready')
